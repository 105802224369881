<template>
  <div component-name="txt-chapter" class="flex flex-col gap-2xl">
    <header class="flex flex-col gap-xs">
      <wrapper-reveal v-if="overline" reveal="fade-from-right">
        <div
          :class="{
            'text-overline-big': size === 'big',
            'text-overline': size === 'regular',
          }"
          v-html="overline"
        ></div>
      </wrapper-reveal>

      <wrapper-split-text reveal="chars">
        <h1
          :class="{
            'text-heading-3': size === 'big',
            'text-heading-5': size === 'regular',
          }"
          v-html="title"
        ></h1>
      </wrapper-split-text>
    </header>

    <wrapper-reveal v-if="text" reveal="fade-from-bottom">
      <p
        :class="{
          'text-heading-6': size === 'big',
          'text-body-big': size === 'regular',
        }"
        v-html="text"
      ></p>
    </wrapper-reveal>

    <wrapper-reveal v-if="button" reveal="fade-from-right">
      <btn-pill
        :size="size"
        :title="button.title"
        :icon="button.icon || undefined"
        :action="button.action"
      />
    </wrapper-reveal>
  </div>
</template>

<script setup>
const props = defineProps({
  size: {
    type: String,
    required: false,
    validator: function (value) {
      return ["regular", "big"].indexOf(value) !== -1;
    },
    default: "regular",
  },
  overline: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: false,
  },
  button: {
    type: Object,
    required: false,
  },
});
</script>
